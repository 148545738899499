import React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  Header,
  SEO,
  Layout,
  SectionWithLogoCloud,
  SectionTestimonials,
  SectionStrap,
  ImageCard,
  Divider,
  RichText,
  Button,
} from "../components"
import { groupBookingSlug } from "../utilities"
import { sectionStrap, sectionTextWithLogoCloud } from "../types"

type Props = {
  data: {
    contentfulGroupBookingPage: {
      contentful_id: string
      image: {
        gatsbyImageData: IGatsbyImageData
        title: string
      }
      description: {
        raw: string
      }
      name: string
      slug: string
      coursesHeading: string
      courses: Array<{
        bookingUrl: string
        contentful_id: string
        description: {
          raw: string
        }
        image: {
          gatsbyImageData: IGatsbyImageData
          title: string
        }
        originalPrice: number
        name: string
        price: number
      }>
      faQs: Array<{
        heading: string
        content: {
          raw: string
        }
      }>
      pageSections: Array<sectionTextWithLogoCloud>
      testimonials: Array<{
        contentful_id: string
        name: string
        position: string
        testimonial: {
          testimonial: string
        }
        photo: {
          gatsbyImageData: IGatsbyImageData
          title: string
        }
      }>
      strap: sectionStrap
      seo: {
        socialImage: {
          file: {
            url: string
          }
        }
        description: {
          description: string
        }
        title: string
      }
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: groupBookingSlug(data.contentfulGroupBookingPage.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL +
    groupBookingSlug(data.contentfulGroupBookingPage.slug)

  const groupBooking = data.contentfulGroupBookingPage

  return (
    <Layout>
      <SEO
        title={groupBooking.seo.title}
        description={groupBooking.seo.description.description}
        image={groupBooking.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={groupBooking.name}
        headerIntroduction={groupBooking.description}
        headerImage={groupBooking.image}
      />
      <main>
        <section className="backgroundGreyLightSuper">
          <section className="wrapperHeight wrapperWidth">
            <h2 className="heading">{groupBooking.coursesHeading}</h2>
            <Divider />
            <div className="groupBookings">
              {groupBooking.courses.map((course) => (
                <ImageCard
                  image={course.image.gatsbyImageData}
                  imageDescription={course.image.title}
                  variant="groupBooking"
                  key={course.contentful_id}
                >
                  <h4 className="groupBookingsCourseName">{course.name}</h4>
                  <RichText content={course.description} tickBullets />
                  <section className="groupBookingsCoursePrice">
                    <span className="groupBookingsCoursePriceHeading">
                      Group booking price
                    </span>
                    <div className="groupBookingsCoursePrices">
                      <span className="groupBookingsCoursePriceGroup">
                        £{course.price.toFixed(2)}
                      </span>
                      <span className="groupBookingsCoursePriceOriginal">
                        £{course.originalPrice.toFixed(2)}
                      </span>
                    </div>
                    <span className="groupBookingsCoursePriceLabel">
                      / per person
                    </span>
                    <Button styles="buttonPrimary" href={course.bookingUrl}>
                      Organise group booking
                    </Button>
                  </section>
                </ImageCard>
              ))}
            </div>
          </section>
        </section>
        {groupBooking.pageSections.map((section) => (
          <SectionWithLogoCloud
            key={section.contentful_id}
            {...section}
            paddingTop={true}
          />
        ))}
        <SectionTestimonials testimonials={groupBooking.testimonials} />
        {/*         {groupBooking.faQs.length > 0 && (
          <SectionFaqs
            backgroundColour="backgroundGreyLightSuper"
            heading="Frequently asked questions"
            faqs={groupBooking.faQs}
          />
        )} */}
        <SectionStrap
          __typename="ContentfulPageSectionsStrap"
          buttonSupportingText={groupBooking.strap.buttonSupportingText}
          description={groupBooking.strap.description}
          name={groupBooking.strap.name}
          buttonText={groupBooking.strap.buttonText}
          buttonUrl={groupBooking.strap.buttonUrl}
          backgroundColour={groupBooking.strap.backgroundColour}
        />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GroupBooking($contentful_id: String!) {
    contentfulGroupBookingPage(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      slug
      description {
        raw
      }
      image {
        gatsbyImageData
        title
      }
      coursesHeading
      courses {
        bookingUrl
        contentful_id
        description {
          raw
        }
        image {
          gatsbyImageData
          title
        }
        originalPrice
        name
        price
      }
      faQs {
        heading
        content {
          raw
        }
      }
      pageSections {
        contentful_id
        heading
        logos {
          title
          gatsbyImageData
        }
        content {
          raw
        }
      }
      testimonials {
        contentful_id
        name
        position
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData
          title
        }
      }
      strap {
        buttonSupportingText
        description
        name
        buttonText
        buttonUrl
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
    }
  }
`
